:root {
  --primary-color: #0a2342; /* Darker blue */
  --secondary-color: #8d1c3d; /* Darker red */
  --background-color: #f8f9fa;
  --text-color: #e6e6e6; /* Light text for dark background */
  --white: #ffffff;
  --gray-100: #f7fafc;
  --gray-200: #edf2f7;
  --gray-300: #e2e8f0;
  --gray-400: #cbd5e0;
}

html {
  font-size: 16px;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.app-wrapper {
  min-height: 100vh;
  background-image: linear-gradient(rgba(10, 35, 66, 0.8), rgba(141, 28, 61, 0.8)), url('/public/images/miss-france-background.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  color: var(--text-color);
}

.app-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content-box {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 100%;
  margin: 1rem auto;
  backdrop-filter: blur(5px);
}

@media (min-width: 576px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .content-box {
    padding: 1.5rem;
    max-width: 90%;
    margin: 1.5rem auto;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 20px;
  }

  .content-box {
    padding: 2rem;
    max-width: 1200px;
    margin: 2rem auto;
  }
}

/* Add any other global styles you need */
