.mybets {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mybets h2 {
  color: var(--white);
  margin-bottom: 20px;
  text-align: center;
}

/* Add more specific styles for your scoreboard content */

.my-bets-container {
    padding: 10px;
    max-width: 100%;
}

.my-bets-container h1 {
    color: var(--white);
    text-align: center;
    margin-bottom: 20px;
}

.bets-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bet-item {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 8px;
    background: #fff;
    color: var(--primary-color);
}

.bet-item h3 {
    margin: 0 0 4px 0;
    font-size: 1rem;
}

.bet-item ul {
    margin: 4px 0;
    padding-left: 20px;
}

.bet-item li {
    margin: 2px 0;
    font-size: 0.9rem;
}

.bet-item p {
    margin: 4px 0;
    font-size: 0.85rem;
    color: var(--primary-color);
}

.welcome-message {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.welcome-message h3 {
    color: var(--primary-color);
    margin-bottom: 10px;
}

.welcome-message p {
    margin-bottom: 10px;
    line-height: 1.5;
}

.no-bets-message {
    text-align: center;
    padding: 40px 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin: 20px;
}

.no-bets-message h2 {
    color: #333;
    margin-bottom: 15px;
}

.no-bets-message p {
    color: #666;
    font-size: 16px;
    line-height: 1.5;
}
