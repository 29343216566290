.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-box {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.login-box h1 {
  color: var(--white);
  margin-bottom: 0.5rem;
}

.login-box p {
  color: var(--text-color);
  margin-bottom: 2rem;
}

.google-login-button {
  background-color: var(--white);
  color: var(--primary-color);
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.google-login-button:hover {
  background-color: var(--gray-100);
}

.google-login-button svg {
  margin-right: 0.5rem;
  color: var(--primary-color);
}

.error-message {
  color: var(--secondary-color);
  margin-bottom: 1rem;
}
