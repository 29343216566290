.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header {
  background: linear-gradient(135deg, rgba(10, 35, 66, 0.9), rgba(141, 28, 61, 0.9));
  color: var(--white);
  padding: 1rem;
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.app-header::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, transparent 80%);
  animation: shimmer 15s infinite linear;
  pointer-events: none;
}

@keyframes shimmer {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.app-header h1 {
  margin: 0 0 0.5rem 0;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-nav {
  position: sticky;
  top: 0;
  background-color: rgb(255, 255, 255);
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  color: var(--primary-color);
  span {
    color: var(--primary-color);
  };
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  backdrop-filter: blur(5px);
}

.app-nav a, .logout-button {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
  display: flex;
  align-items: auto;
  padding: 0.5rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  flex: 1 1 auto;
  justify-content: center;
  text-align: center;
  min-width: 20px;
}

.app-nav a:hover, .logout-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.app-nav a.active {
  color: var(--primary-color);
  background-color: rgba(255, 255, 255, 0.2);
}

.app-nav a svg, .logout-button svg {
  margin-right: 0.5rem;
}

.logout-button {
  background-color: var(--secondary-color);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  color: var(--white);
  border-radius: 20px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 0;
  z-index: 1;
  overflow: hidden;
}

.logout-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  transition: all 0.4s;
}

.logout-button:hover::before {
  left: 100%;
}

.logout-button:hover {
  background-color: #a82348;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.logout-button svg {
  margin-right: 0.5rem;
}

.main-content {
  flex-grow: 1;
  padding: 1rem;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  color: var(--primary-color);
}

@media (min-width: 576px) {
  .app-nav a, .logout-button {
    flex: 0 1 auto;
  }
}

@media (min-width: 768px) {
  .header-content {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }

  .app-header h1 {
    font-size: 1.8rem;
    margin: 0;
  }

  .logout-button {
    margin-top: 0;
    margin-left: 1rem;
  }

  .app-nav {
    padding: 0.5rem 2rem;
    justify-content: center;
    gap: 1rem;
  }

  .main-content {
    padding: 2rem;
    max-width: 90%;
  }
}

@media (min-width: 1024px) {
  .main-content {
    max-width: 1200px;
  }
}
