.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px); /* Adjust based on your header height */
  text-align: center;
}

.home-header {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-header h1 {
  color: white;
  margin: 0;
  font-size: 24px;
}

.logout-button {
  background-color: var(--accent-color);
  color: var(--primary-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.welcome-message {
  color: white;
  font-size: 18px;
  text-align: center;
  margin-bottom: 30px;
}

.home-nav {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.nav-item {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  text-decoration: none;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-5px);
}

.nav-item svg {
  font-size: 24px;
  margin-bottom: 10px;
}

.login-message {
  color: white;
  text-align: center;
}

.login-message a {
  color: var(--accent-color);
}

@media (max-width: 768px) {
  .home-header h1 {
    font-size: 20px;
  }

  .home-nav {
    grid-template-columns: 1fr;
  }
}

/* Fade transition */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.home-container {
  text-align: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.home-container h1 {
  margin-bottom: 1rem;
  font-size: 28px;
  color: #333;
}

.home-container p {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #555;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 30px;
}
