.candidates {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
}

.candidates h2 {
    color: var(--primary-color);
    margin-bottom: 20px;
    text-align: center;
}

.candidates-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.candidate-item {
    flex: 0 0 48%;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    transition: transform 0.3s;
    background-color: #f5f5f5;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.candidate-item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.candidate-image-container {
    width: 100%;
    height: 150px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    padding-top: 75%;
}

.candidate-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.candidate-info {
    text-align: center;
    margin-top: 5px;
    padding: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.candidate-info h3 {
    color: var(--primary-color);
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 1rem;
}

.candidate-info p {
    margin: 0;
    color: #666;
    font-size: 0.9rem;
}

@media (max-width: 600px) {
    .candidates-list {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}

.filter {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.filter-button {
    background-color: #f0f0f0;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s;
}

.filter-button.active {
    background-color: #ff69b4; /* Miss France theme color */
    color: white;
}

.filter-button:hover {
    background-color: #ff69b4; /* Miss France theme color */
    color: white;
}

.search {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.search-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 300px;
}

@media (min-width: 768px) {
    .candidate-item {
        flex: 0 0 30%;
    }
}
