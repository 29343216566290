.submit-bets-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.submit-bets-content {
    width: 100%;
    max-width: 800px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    padding-top: 10px;
}

.submit-bets-content h2 {
    text-align: center;
    color: var(--primary-color); /* Change this to match the exact blue color used in other pages */
    margin-bottom: 20px;
}

.bet-category {
    position: relative;
    padding-top: 0;
    margin-top: 0;
}

.bet-category-header {
    position: sticky;
    top: 60px;
    background-color: white;
    padding: 15px;
    z-index: 100;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-top: 5px;
}

.bet-category-header h3 {
    margin: 0;
}

.bet-category h3 {
    margin-bottom: 15px;
    color: #333;
    margin: 5px 0;
}

.bet-selection-label {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--primary-color); /* Darker text color for better contrast */
    background-color: #f9f9f9; /* Light background color */
    padding: 10px; /* Add padding for better readability */
    border-radius: 5px; /* Rounded corners */
    margin: 5px 0;
}

.bet-selection {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}

.bet-button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.bet-button.active {
    background-color: #007bff;
    color: white;
    font-weight: bold;
}

.bet-button:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
}

.candidates-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}

.candidate-item {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    transition: transform 0.2s ease;
    background-color: #ffffff;
}

.candidate-item.selected {
    border-color: #007bff;
    background-color: #e6f2ff;
}

.candidate-item:active {
    transform: scale(0.98);
}

.candidate-image-container {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
}

.candidate-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.candidate-info {
    margin-top: 10px;
    text-align: center;
}

.candidate-region {
    display: block;
    font-weight: bold;
    color: #333;
    font-size: 14px;
    margin-bottom: 4px;
}

.candidate-name {
    display: block;
    color: #666;
    font-size: 12px;
}

/* Ensure text is always visible */
.candidate-region,
.candidate-name {
    position: relative;
    z-index: 1;
}

.bet-category button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.bet-category button:hover:not(:disabled) {
    background-color: #45a049;
}

.bet-category button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

button {
    background-color: #ff69b4; /* Miss France theme color */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

button:hover:not(:disabled) {
    background-color: #ff1493; /* Darker shade of the theme color */
}

@media (max-width: 600px) {
    .candidates-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .candidate-region {
        font-size: 14px;
    }

    .candidate-name {
        font-size: 12px;
    }
}

.submit-button {
    padding: 8px 20px;
    border-radius: 20px;
    border: none;
    background-color: #28a745 !important; /* Force green color for submit */
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.submit-button:hover:not(:disabled) {
    background-color: #218838 !important; /* Darker green on hover */
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.submit-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.7;
}

.selection-counter {
    background-color: #f5f5f5;
    padding: 8px 16px;
    border-radius: 20px;
    margin: 10px 0;
    font-weight: bold;
    color: #333;
    text-align: center;
}

.selection-counter.complete {
    background-color: #e6ffe6;
    color: #2e7d32;
}

.selection-counter.error {
    background-color: #ffe6e6;
    color: #d32f2f;
}

.button-group {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

/* Clear button styles */
.button-group .clear-button {
    padding: 8px 20px;
    border-radius: 20px;
    border: 1px solid #dc3545;
    background-color: transparent !important;
    color: #dc3545 !important;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 0.5;
}

/* Clear button when selections exist */
.bet-category:has(.candidate-item.selected) .clear-button {
    background-color: #dc3545 !important;
    color: white !important;
    opacity: 1;
}

/* Submit button styles */
.button-group .submit-button {
    padding: 8px 20px;
    border-radius: 20px;
    border: none;
    background-color: #cccccc !important;
    color: white !important;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 1;
}

.button-group .submit-button:not(.disabled) {
    background-color: #28a745 !important;
}

.button-group .submit-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.button-group .submit-button.disabled {
    background-color: #ccc !important;
    cursor: not-allowed;
    opacity: 0.7;
}

.search {
    position: sticky;
    top: 120px;
    z-index: 100;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 8px;
    margin-bottom: 10px;
}

.search-input {
    margin: 5px 0;
    width: 80%;
    max-width: 300px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
