.scoreboard {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.scoreboard h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
  text-align: center;
}

/* Add more specific styles for your scoreboard content */

.scoreboard-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.scoreboard-container h2 {
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 20px;
}

.scoreboard-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.scoreboard-item {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.rank {
    font-size: 1.2em;
    font-weight: bold;
    margin-right: 15px;
    min-width: 30px;
}

/* Style for top 3 positions */
.scoreboard-item:nth-child(1) {
    background-color: #ffd700; /* Gold */
}

.scoreboard-item:nth-child(2) {
    background-color: #c0c0c0; /* Silver */
}

.scoreboard-item:nth-child(3) {
    background-color: #cd7f32; /* Bronze */
}

.scoreboard-item h3 {
    margin: 0;
    flex: 1;
}

.scoreboard-item p {
    margin: 0;
    font-weight: bold;
}
