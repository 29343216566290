.admin-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.results-section {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.category-section {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    margin: 15px 0;
}

.position-slot {
    display: flex;
    align-items: center;
    gap: 10px;
}

.position-number {
    font-weight: bold;
    min-width: 30px;
}

select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.save-button {
    padding: 12px 24px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}

.save-button:hover {
    background-color: #45a049;
}

.save-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}
